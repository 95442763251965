import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "../../environments/environment";
import { timer } from "rxjs";
import { AuthenticationService } from "../_services";

@Injectable()
export class SuperadminGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var token;

    // token = of(localStorage.getItem(`${environment.appName}` + '_user'));

    if (localStorage.getItem(`${environment.appName}` + "_token")) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "tma_token")) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "_vehicleuser")
    ) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "chess_token")) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "fest_token")) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "iqac_token")) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "finacial_token")
    ) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "law_token")) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "adhikshana_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "hacknite_token")
    ) {
      return true;
    }else if (
      localStorage.getItem(`${environment.appName}` + "arbitrationworkshop_token")
    ) {
      return true;
    }else if (localStorage.getItem(`${environment.appName}` + "eraya_token")) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "mujo_token")) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "solstice_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "faculty_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "refleckta_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "conference_token")
    ) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "aley_token")) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "cina_token")) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "hostels_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "daycare_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "basketball_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "bazaar_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "cricket_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "tessellate_token")
    ) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "fdp_token")) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "gala_token")) {
      return true;
    } else if (localStorage.getItem(`${environment.appName}` + "manam_token")) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "sustain_token")
    ) {
      return true;
    } else if (
      localStorage.getItem(`${environment.appName}` + "popcon_token")
    ) {
      return true;
    } else {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url },
      });
      localStorage.setItem(`${environment.appName}` + "_token", "");
      return false;
    }
  }
}
