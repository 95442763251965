import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthSuperadminGuard, AuthVendorGuard } from "./_guards";
import { almashineSuperadminGuard } from "./_guards/almashine.guard";
import { SuperadminGuard } from "./_guards/conference.guard";
// canActivate: [AuthSuperadminGuard],
const routes: Routes = [
  {
    path: "registration",
    loadChildren: () =>
      import("./registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: "",
    loadChildren: () =>
      import("./campus-card/campus-card.module").then(
        (m) => m.CampusCardModule
      ),
  },
  {
    path: "help",
    loadChildren: () => import("./help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "guidelines",
    loadChildren: () =>
      import("./guidelines/guidelines.module").then((m) => m.GuidelinesModule),
  },

  {
    path: "hackathon-registration",
    loadChildren: () =>
      import("./hackathon_reg/hackathon_reg.module").then(
        (m) => m.HackathonregModule
      ),
  },

  {
    path: "hackathon",
    loadChildren: () =>
      import("./hackathon/hackathon.module").then((m) => m.HackathonModule),
  },

  //  { path: 'Almashine', loadChildren: () => import('./almashaines/almashaines.module').then(m => m.AlmashainesModule) },

  // { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "bangalore-sports-complex",
    loadChildren: () =>
      import("./blr-sport-complex/blr-sport-complex.module").then(
        (m) => m.BlrSportComplexModule
      ),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "my-account",
    loadChildren: () =>
      import("./my-account/my-account.module").then((m) => m.MyAccountModule),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "success",
    loadChildren: () =>
      import("./success/success.module").then((m) => m.SuccessModule),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "payment-success",
    loadChildren: () =>
      import("./payment-success/payment-success.module").then(
        (m) => m.SuccessModule
      ),
  },
  {
    path: "payment-failure",
    loadChildren: () =>
      import("./payment-failure/payment-failure.module").then(
        (m) => m.failureModule
      ),
  },
  {
    path: "failure",
    loadChildren: () =>
      import("./failure/failure.module").then((m) => m.failureModule),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "terms-condition",
    loadChildren: () =>
      import("./terms-of-service/terms-of-service.module").then(
        (m) => m.TermsServiceModule
      ),
  },

  // swimming-pool
  {
    path: "add-beneficiary",
    loadChildren: () =>
      import("./add-beneficiary/add-beneficiary.module").then(
        (m) => m.AddBeneficiaryModule
      ),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "sports-complex",
    loadChildren: () =>
      import("./sports-complex/sports-complex.module").then(
        (m) => m.SportsComplexModule
      ),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "swimming-pool",
    loadChildren: () =>
      import("./swimming-pool/swimming-pool.module").then(
        (m) => m.SwimmingPoolModule
      ),
    canActivate: [AuthSuperadminGuard],
  },
  {
    path: "quick-pay",
    loadChildren: () =>
      import("./quick-pay/quick-pay.module").then((m) => m.QuickPayModule),
    canActivate: [AuthSuperadminGuard],
  },

  {
    path: "rules-and-regulation",
    loadChildren: () =>
      import("./rules-and-regulation/rules-and-regulation.module").then(
        (m) => m.RulesAndRegulationModule
      ),
  },
  {
    path: "contactus",
    loadChildren: () =>
      import("./contactus/contactus.module").then(
        (m) => m.ContactUsComponentModule
      ),
  },
  {
    path: "Almashine",
    loadChildren: () =>
      import("./almashaine-register/almashaine-register.module").then(
        (m) => m.AlmashaineRegisterModule
      ),
    canActivate: [almashineSuperadminGuard],
  },
  {
    path: "almashine",
    loadChildren: () =>
      import("./almashaines/almashaines.module").then(
        (m) => m.AlmashainesModule
      ),
  },
  {
    path: "updatealmashine",
    loadChildren: () =>
      import("./upldate-almashine/upldate-almashine.module").then(
        (m) => m.UpldateAlmashineModule
      ),
  },
  // {
  //   path: "campus-card",
  //   loadChildren: () =>
  //     import("./campus-card/campus-card.module").then(
  //       (m) => m.CampusCardModule
  //     ),
  // },
  {
    path: "manipal-campus",
    loadChildren: () =>
      import("./mit-card/mit-card.module").then((m) => m.MitCardModule),
  },
  {
    path: "bangalore-campus",
    loadChildren: () =>
      import("./bangalore-card/bangalore-card.module").then(
        (m) => m.BangaloreCardModule
      ),
  },

  // {
  //   path: "almashineLogin",
  //   loadChildren: () =>
  //     import("./almashine-login/almashine-login.module").then(
  //       (m) => m.AlmashineLoginModule
  //     ),
  // },
  {
    path: "conference-details",
    loadChildren: () =>
      import("./conference-details/conference-details.module").then(
        (m) => m.ConferenceDetailsModule
      ),
  },
  {
    path: "conference-accomdation",
    loadChildren: () =>
      import("./conference-accomdation/conference-accomdation.module").then(
        (m) => m.ConferenceAccomdationModule
      ),
  },
  {
    path: "conference-success",
    loadChildren: () =>
      import("./conference-success/conference-success.module").then(
        (m) => m.ConferenceSuccessModule
      ),
    canActivate: [SuperadminGuard],
  },
  {
    path: "card-select",
    loadChildren: () =>
      import("./card-select/card-select.module").then(
        (m) => m.CardSelectModule
      ),
  },
  {
    path: "bangalore-conference",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "sports",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "almashine-mit",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },

  {
    path: "bangalore-sports",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  // {
  //   path: "Tech-solstice-nexus-Login",
  //   loadChildren: () =>
  //     import("./login/login.module").then((m) => m.LoginModule),
  // },
  // {
  //   path: "chess-event-Login",
  //   loadChildren: () =>
  //     import("./login/login.module").then((m) => m.LoginModule),
  // },
  // {
  //   path: "chess-event-Login-Type",
  //   loadChildren: () =>
  //     import("./chess-event-type-login/chess-event-type-login.module").then(
  //       (m) => m.ChessEventTypeLoginModule
  //     ),
  // },
  {
    path: "falak-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },

  {
    path: "cricket-tournament-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },

  {
    path: "Coursera",
    loadChildren: () =>
      import("./coursera/coursera.module").then((m) => m.CourseraModule),
  },
  {
    path: "Arbitration-Moot-Competition",
    loadChildren: () =>
      import("./tma-pai-payment/tma-pai-payment.module").then(
        (m) => m.TMAPaiPaymentModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },

  // {
  //   path: "Arbitration-Moot-Competition-login",
  //   loadChildren: () =>
  //   import("./login/login.module").then((m) => m.LoginModule),
  //   // canActivate: [AuthSuperadminGuard],
  // },
  {
    path: "Dil-Aur-Dimaag-login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "magu-daycare-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "kALA-FEST-login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "IQAC-MIT-login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },

  // {
  //   path: "Financial-Econometrics-login",
  //   loadChildren: () =>
  //   import("./login/login.module").then((m) => m.LoginModule),
  //   // canActivate: [AuthSuperadminGuard],
  // },
  {
    path: "Legal-Foundation-login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "vehicle-registration",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "daycare-userType",
    loadChildren: () =>
      import("./daycare-selecttype/daycare-selecttype.module").then(
        (m) => m.DaycareSelecttypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "sustainnovate-type",
    loadChildren: () =>
      import("./sustainnovate-type/sustainnovate-type.module").then(
        (m) => m.SustainnovateTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "sustainnovate-academic-event",
    loadChildren: () =>
      import(
        "./sustainnovate-academic-event/sustainnovate-academic-event.module"
      ).then((m) => m.SustainnovateAcademicEventModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "sustainnovate-sports-event",
    loadChildren: () =>
      import(
        "./sustainnovate-sports-event/sustainnovate-sports-event.module"
      ).then((m) => m.SustainnovateSportsEventModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "daycare-registration-form",
    loadChildren: () =>
      import(
        "./daycare-registration-form/daycare-registration-form.module"
      ).then((m) => m.DaycareRegistrationFormModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "chess-event-payment",
    loadChildren: () =>
      import("./dil-aur-dimaag-payment/dil-aur-dimaag-payment.module").then(
        (m) => m.DilAurDimaagPaymentModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Finacial-Workshop-payment",
    loadChildren: () =>
      import("./fincial-workshop/fincial-workshop.module").then(
        (m) => m.FincialWorkshopModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Legal-Foundation-payment",
    loadChildren: () =>
      import("./legal-foundation/legal-foundation.module").then(
        (m) => m.LegalFoundationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "KALA-FEST-payment",
    loadChildren: () =>
      import("./kala-fest-payment/kala-fest-payment.module").then(
        (m) => m.KALAFESTPaymentModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "IQAC-MIT",
    loadChildren: () =>
      import("./iqac-mit/iqac-mit.module").then((m) => m.IqacMitModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Faculty-Development-Program-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Adhikshana-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Adhikshana-Registration",
    loadChildren: () =>
      import("./adhikshana-registration/adhikshana-registration.module").then(
        (m) => m.AdhikshanaRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Faculty-Development-Program-Registration-Type",
    loadChildren: () =>
      import(
        "./faculty-development-program-registration/faculty-development-program-registration.module"
      ).then((m) => m.FacultyDevelopmentProgramRegistrationModule),
    // canActivate: [AuthSuperadminGuard],
  },
  // {
  //   path: "Eraya-Login",
  //   loadChildren: () =>
  //   import("./login/login.module").then((m) => m.LoginModule),
  //   // canActivate: [AuthSuperadminGuard],
  // },
  {
    path: "MUJO-Underground-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Reflekta-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Reflekta-Login-Type",
    loadChildren: () =>
      import("./reflekta-login-type/reflekta-login-type.module").then(
        (m) => m.ReflektaLoginTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Reflekta-Registration",
    loadChildren: () =>
      import("./reflekta-registration/reflekta-registration.module").then(
        (m) => m.ReflektaRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Aley",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "CinA-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Aley-Login-Type",
    loadChildren: () =>
      import("./aley-login-type/aley-login-type.module").then(
        (m) => m.AleyLoginTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Aley-Registration",
    loadChildren: () =>
      import("./aley-registration/aley-registration.module").then(
        (m) => m.AleyRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "MUJO-registration",
    loadChildren: () =>
      import("./mujo-registration/mujo-registration.module").then(
        (m) => m.MujoRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "cinA-registration",
    loadChildren: () =>
      import("./cin-a/cin-a.module").then((m) => m.CinAModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Eraya-Login-Type",
    loadChildren: () =>
      import("./eraya-login-type/eraya-login-type.module").then(
        (m) => m.ErayaLoginTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "basketball-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule), // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "tesellate-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule), // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Falak-registration",
    loadChildren: () =>
      import("./eraya-registration/eraya-registration.module").then(
        (m) => m.ErayaRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "basketball-registration",
    loadChildren: () =>
      import("./basketball-registration/basketball-registration.module").then(
        (m) => m.BasketballRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Tech-solstice-nexus-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  // {
  //   path: "hack-nite-login",
  //   loadChildren: () =>
  //     import("./login/login.module").then((m) => m.LoginModule),
  //   // canActivate: [AuthSuperadminGuard],
  // },
  {
    path: "arbitration-workshop-login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "arbitration-workshop-registartion",
    loadChildren: () =>
      import(
        "./arbitration-workshop-registration/arbitration-workshop-registration.module"
      ).then((m) => m.ArbitrationWorkshopRegistraionModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "arbitration-workshop",
    loadChildren: () =>
      import("./arbitration-workshop/arbitration-workshop.module").then(
        (m) => m.ArbitrationWorkshopModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Tech-Solstice-Registartion-type",
    loadChildren: () =>
      import(
        "./tech-solstice-registration-type/tech-solstice-registration-type.module"
      ).then((m) => m.TechSolsticeRegistrationTypeModule),
    // canActivate: [AuthSuperadminGuard],
  },
  // {
  //   path: "hack-nite-registartion-type",
  //   loadChildren: () =>
  //     import(
  //       "./hack-nite-registration-type/hack-nite-registration-type.module"
  //     ).then((m) => m.HackNiteRegistrationTypeModule),
  //   // canActivate: [AuthSuperadminGuard],
  // },
  // {
  //   path: "hack-nite-registartion",
  //   loadChildren: () =>
  //     import("./hack-nite-registration/hack-nite-registration.module").then(
  //       (m) => m.HackNiteRegistrationModule
  //     ),
  //   // canActivate: [AuthSuperadminGuard],
  // },

  {
    path: "Tech-Solstice-Registartion",
    loadChildren: () =>
      import(
        "./tech-solstice-registartion/tech-solstice-registartion.module"
      ).then((m) => m.TechSolsticeRegistartionModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "alige-devops-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "login-Type",
    loadChildren: () =>
      import("./agile-and-devops-type/agile-and-devops-type.module").then(
        (m) => m.AgileAndDevopsTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Faculty-Development-Program-Type",
    loadChildren: () =>
      import(
        "./agile-and-devops-selecttype/agile-and-devops-selecttype.module"
      ).then((m) => m.AgileAndDevopsSelecttypeModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "cricket-tournament-userType",
    loadChildren: () =>
      import(
        "./cricket-tournament-selecttype/cricket-tournament-selecttype.module"
      ).then((m) => m.CricketTournamentSelecttypeModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "tessellate-userType",
    loadChildren: () =>
      import(
        "./cricket-tournament-selecttype/cricket-tournament-selecttype.module"
      ).then((m) => m.CricketTournamentSelecttypeModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "tessellate-eventtype",
    loadChildren: () =>
      import("./tessellate-event-type/tessellate-event-type.module").then(
        (m) => m.TessellateEventTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "tessellate-registration",
    loadChildren: () =>
      import("./tessellate-registration/tessellate-registration.module").then(
        (m) => m.TessellateRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "cricket-tournament-registration",
    loadChildren: () =>
      import(
        "./cricket-tournament-registration/cricket-tournament-registration.module"
      ).then((m) => m.CricketTournamentRegistrationModule),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "alige-devops-Registration",
    loadChildren: () =>
      import(
        "./agile-and-devops-registration/agile-and-devops-registration.module"
      ).then((m) => m.AgileAndDevopsRegistrationModule),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "mahe-hostels-Registration",
    loadChildren: () =>
      import(
        "./mahe-hostels-registartion/mahe-hostels-registartion.module"
      ).then((m) => m.MaheHostelsRegistartionModule),
    // canActivate: [AuthSuperadminGuard],
  },

  {
    path: "conference-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "bazaar-mic-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  // {
  //   path: "FDP-machine-learning-Login",
  //   loadChildren: () =>
  //     import("./login/login.module").then((m) => m.LoginModule),
  //   // canActivate: [AuthSuperadminGuard],
  // },
  {
    path: "gala-night-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "popCon-prom-login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "sustainnovate-login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "event-registration",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "event-detail-registration",
    loadChildren: () =>
      import(
        "./kenny-sebastian-registration/kenny-sebastian-registration.module"
      ).then((m) => m.KennySebastianRegistrationModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "FDP-Registration",
    loadChildren: () =>
      import("./fdp-registration/fdp-registration.module").then(
        (m) => m.FdpRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "Gala-Registration",
    loadChildren: () =>
      import("./gala-registration/gala-registration.module").then(
        (m) => m.GalaRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "popcom-prom-event",
    loadChildren: () =>
      import("./popcon-prom-event/popcon-prom-event.module").then(
        (m) => m.PopconPromEventModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "mahe-hostels-Login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "conference-LoginType",
    loadChildren: () =>
      import("./conference-login-type/conference-login-type.module").then(
        (m) => m.ConferenceLoginTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "conference-userType",
    loadChildren: () =>
      import("./conference-user-type/conference-user-type.module").then(
        (m) => m.ConferenceUserTypeModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "vehicle-registration-form",
    loadChildren: () =>
      import("./vehicle-registration/vehicle-registration.module").then(
        (m) => m.VehicleRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "bazzar-mic-registration-form",
    loadChildren: () =>
      import("./bazzar-mic/bazzar-mic.module").then((m) => m.BazzarMicModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "vehicle-registration-register",
    loadChildren: () =>
      import(
        "./vehicle-registration-register/vehicle-registration-register.module"
      ).then((m) => m.VehicleRegistrationRegisterModule),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "review-vehicle-form",
    loadChildren: () =>
      import("./review-vehicle-form/review-vehicle-form.module").then(
        (m) => m.ReviewVehicleFormModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },
  {
    path: "conference-registartion",
    loadChildren: () =>
      import("./conference-registration/conference-registration.module").then(
        (m) => m.ConferenceRegistrationModule
      ),
    // canActivate: [AuthSuperadminGuard],
  },

  // {
  //   path: "vehicle-registration-form",
  //   loadChildren: () =>
  //     import("./vehicle-registration/vehicle-registration-routing.module").then(
  //       (m) => m.V
  //     ),
  // },

  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
